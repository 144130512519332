import React from "react";
import { graphql } from "gatsby";
import Page from '../components/Page/page';
import './news-template.css';

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
    // if you remove graphql below, this totally breaks
    location
}) {
    const { markdownRemark } = data; // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark;

    return (
        <Page location={location}
            animateHeader
            seoTitle={frontmatter.title}
            title={frontmatter.title}
            subtitle={
                <>
                    {frontmatter.subtitle}
                    <br />
                    {frontmatter.productUrls?.map((productUrl) => (
                        <a href={productUrl.url} rel="noreferrer" target="_blank">
                            <button className="btn btn-primary px-3 py-1 mt-3 me-2">
                                Get it
                                {productUrl.name
                                    ? ` on ${productUrl.name}`
                                    : ` for ${formatPrice(frontmatter.isOnSale, frontmatter.price, frontmatter.salePrice)}`
                                }
                            </button>
                        </a>
                    ))}
                </>
            }>
            <div className="news-post-content">
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Page>
    )
}

const formatPrice = (isOnSale, amount, salePrice) => {
    if (amount === 0) {
        return 'FREE'
    } else if (isOnSale) {
        return (
            <>
                <s>${amount.toFixed(2)}</s> <b>${salePrice.toFixed(2)}</b>
            </>
        );
    } else {
        return `$${amount.toFixed(2)}`;
    }
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        subtitle
        isOnSale
        price
        productUrls {
            url
            name
        }
        salePrice
      }
    }
  }
`;
